<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>包裹管理</el-breadcrumb-item>
      <el-breadcrumb-item>操作记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card shadow="never">
      <div class="change">
        <el-input v-model="queryInfo.expressWaybillNum" clearable placeholder="请输入快递单号"></el-input>
        <el-input v-model="queryInfo.operatorAccount" clearable placeholder="请输入操作人账号"></el-input>
        <el-input v-model="queryInfo.operatorName" clearable placeholder="请输入操作人姓名"></el-input>
        <el-input v-model="queryInfo.siteName" clearable placeholder="请输入站点名称"></el-input>
        <el-button type="primary" clearable @click="search">查询</el-button>
        <el-button>导出</el-button>
      </div>
      <!-- 卡片视图 -->
      <!-- 用户信息框 -->
      <el-table :data="accessList" border stripe style="width: 100%"
        :header-cell-style="{ background: 'rgba(22, 119, 255, 0.0300)' }">
        <el-table-column type="index" align="center"> </el-table-column>
        <el-table-column prop="expressWaybillNum" label="快递单号" align="center">
        </el-table-column>
        <el-table-column prop="operatorAccount" label="操作人账号" align="center">
        </el-table-column>
        <el-table-column prop="operatorName" label="操作人" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" label="操作时间" align="center">
        </el-table-column>
        <el-table-column prop="type" label="操作类型" align="center">
          <template slot-scope="scope">
            {{ map[scope.row.type] }}
          </template>
        </el-table-column>
        <el-table-column prop="siteName" label="站点名称" align="center">
        </el-table-column>
        <el-table-column prop="operation" label="操作" align="center" width="">
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.current" :page-sizes="[10, 20, 50, 100, 200]" :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 操作记录列表数据
      accessList: [],
      queryInfo: {
        current: 1,
        expressWaybillNum: '',
        operatorAccount: '',
        operatorName: '',
        siteName: '',
        type: '',
        endTime: '',
        size: 10,
      },
      total: 0,
      map: ['', '入库', '出库', '签收', '拒收'],
    }
  },
  // 钩子   创建后执行此函数
  created() {
    this.getAccessList()
  },
  methods: {
    // 获取员工列表
    getAccessList() {
      this.axios.post('/api/site/operate/page', this.queryInfo).then((res) => {
        // console.log(res)
        this.accessList = res.data.data.records
        this.total = res.data.data.total
      })
    },
    // 搜索框
    search() {
      this.queryInfo.current = 1
      this.getAccessList
    },
    // 监听一页有几条数据
    handleSizeChange(newSize) {
      // console.log(newSize);
      this.queryInfo.size = newSize
      this.getAccessList()
    },
    // 监听第几页
    handleCurrentChange(newPage) {
      // console.log(newPage);
      this.queryInfo.current = newPage
      this.getAccessList()
    },
  },
  // 搜索数据列表跟随变化
  watch: {
    queryInfo: {
      handler: function (newVal) {
        // console.log(newVal.queryInfo)
        this.getAccessList()
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}

.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.el-table {
  margin-top: 20px;
}

.el-input {
  margin-right: 8px;
  width: 240px;
}

.change {
  margin: 20px 0;
}

.right {
  margin-left: 61px;
  color: rgba(255, 59, 48, 1);
  font-size: 14px;
}
</style>
